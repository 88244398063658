import { DECIMALS, SYMBOLS } from "@/constants/wallet";
import { formatUnits, parseUnits } from "viem";

export const numberFormatter = new Intl.NumberFormat("en-US", {
  style: "decimal",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  useGrouping: true,
});

export const percentageFormatter = new Intl.NumberFormat("en-US", {
  style: "percent",
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

export const currencyUSDFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const currencyUSDCompactFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  notation: "compact",
  compactDisplay: "short",
  currency: "USD",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const decimalSharesFormatter = new Intl.NumberFormat("en-US", {
  style: "decimal",
  minimumFractionDigits: 2,
  maximumFractionDigits: 4,
});

export const decimalUSDCFormatter = new Intl.NumberFormat("en-US", {
  style: "decimal",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export function formatNumber(value: number): string {
  return numberFormatter.format(value);
}

export function formatUSDCtoUSD(amount: bigint, includeTicker = true): string {
  const formattedAmount = formatUnits(amount, DECIMALS.USDC);
  if (!includeTicker) return formattedAmount;

  const numberValue = Number.parseFloat(formattedAmount);
  return currencyUSDFormatter.format(numberValue);
}

export function formatNavToUSDUnits(
  amount: bigint,
  includeTicker = true,
): string {
  const formattedAmount = formatUnits(amount, DECIMALS.NAV);
  if (!includeTicker) return formattedAmount;

  const numberValue = Number.parseFloat(formattedAmount);
  return currencyUSDFormatter.format(numberValue);
}
export function formatToUSDCUnits(
  amount: bigint,
  includeTicker = true,
): string {
  const formattedAmount = formatUnits(amount, DECIMALS.USDC);
  if (!includeTicker) return formattedAmount;
  const formattedString = decimalUSDCFormatter.format(
    Number.parseFloat(formattedAmount),
  );
  return `${formattedString} ${SYMBOLS.USDC}`;
}

export function parseToSharesUnits(amount: string): bigint {
  return parseUnits(amount, DECIMALS.SHARES);
}

export function formatToSharesUnits(
  amount: bigint,
  includeTicker = true,
): string {
  const formattedAmount = formatUnits(amount, DECIMALS.SHARES);

  if (!includeTicker) return formattedAmount;

  const sharesValue = decimalSharesFormatter.format(
    Number.parseFloat(formattedAmount),
  );

  return `${sharesValue} ${SYMBOLS.SHARES}`;
}

export const formatToCompactUSD = (amount: number): string => {
  return currencyUSDCompactFormatter.format(amount);
};

export function parseToUSDCUnits(amount: string): bigint {
  return parseUnits(amount, DECIMALS.USDC);
}

export const formatToPercentage = (amount: number): string => {
  return percentageFormatter.format(amount);
};

export function formatToUSD(amount: number, includeTicker = true): string {
  const displayValue = amount < 0 && amount > -0.005 ? 0 : amount;

  if (!includeTicker) {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(displayValue);
  }

  return currencyUSDFormatter.format(displayValue);
}

export const MAX_UINT256 = BigInt(
  "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff",
);

/// TODO: GET initial share price from contract

export const INITIAL_SHARE_PRICE = 1;

/// TODO: Get theses values in another way
export const DECIMALS = {
  SHARES: 18,
  USDC: 6,
  NAV: 8,
};

/// TODO: Get theses values with contract interactions
export const SYMBOLS = {
  USDC: "USDC",
  USD: "USD",
  SHARES: "kpkUSDafv0",
};

export const CHAINS_DEBANK: Record<string, string> = {
  xdai: "Gnosis",
  eth: "Ethereum",
  arb: "Arbitrum",
  op: "Optimism",
  base: "Base",
};

export const PROTOCOLS_DEBANK: Record<string, string> = {
  gnosis: "Savings xDAI",
  aave3: "Aave V3",
  makerdao: "Maker",
  compound3: "Compound V3",
};

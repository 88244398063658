import { useFundPortfolioContext } from "@/context/fundPortfolioContext";
import { useMemo } from "react";
import {
  AssetDistributionWrapper,
  AssetsDistributionLoader,
} from "../PortfolioAssetsDistribution";
import { DoughnutChart } from "./PortfolioDoughnutChart";
import _ from "lodash";
import ErrorDisplay from "@/components/ErrorDisplay";
import type { TokenList } from "@/data/interfaces/fund_state";

export function FundsByAssetChart() {
  const { data, isLoading, isError } = useFundPortfolioContext();

  const chartData = useMemo(() => {
    return walletTokens(data?.tokens, data?.totalWalletUsdValue);
  }, [data]);

  if (data?.tokens?.length === 0) return null;

  return (
    <AssetDistributionWrapper title="Total funds by asset">
      {isLoading ? (
        <AssetsDistributionLoader />
      ) : isError || !chartData ? (
        <div className="flex-col flex py-20 justify-center">
          <ErrorDisplay />
        </div>
      ) : (
        <DoughnutChart data={chartData} />
      )}
    </AssetDistributionWrapper>
  );
}

function walletTokens(
  tokens: TokenList | undefined,
  totalWalletUsdValue: number | undefined,
) {
  if (!tokens || !totalWalletUsdValue) return undefined;

  const groupedTokens = _.groupBy(tokens, "symbol");

  const aggregatedTokens = _.map(groupedTokens, (group, symbol) => {
    const totalUsdValue = _.sumBy(group, (token) => token.price * token.amount);

    return {
      label: symbol,
      value: totalUsdValue,
    };
  });

  const sortedData = _.orderBy(aggregatedTokens, ["value"], ["desc"]);

  const topTokens = sortedData.slice(0, 5);

  const othersValue = _.sumBy(sortedData.slice(5), "value");

  return othersValue > 0
    ? [...topTokens, { label: "Others", value: othersValue }]
    : topTokens;
}

import type { DateRangeType } from "@/constants/date";
import { useConfigContext } from "@/context/configContext";
import { getFundStateTimeseries } from "@/data/api/fund";
import { formatNavToUSDUnits, formatToUSD } from "@/utils/formatters";
import { useQuery } from "@tanstack/react-query";

export interface HistoricalNavParams {
  startDate: string;
  endDate: string;
  dateRangeFilter: DateRangeType;
}

export const useHistoricalNav = ({
  startDate,
  endDate,
  dateRangeFilter,
}: HistoricalNavParams) => {
  const { fund } = useConfigContext();
  const { data, error, isFetching, isFetchedAfterMount, isError } = useQuery({
    queryKey: ["historicalNav", dateRangeFilter],
    queryFn: () => getFundStateTimeseries(fund.name, startDate, endDate),
    staleTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  const chartData = data?.map((fund) => {
    const usdc = Number(formatNavToUSDUnits(fund.usd, false));
    return {
      timestamp: Number(fund.maxTimestamp) * 1000,
      value: Number(formatToUSD(usdc, false)),
    };
  });

  return {
    data: chartData,
    isError,
    error,
    isFetching,
    isFetchedAfterMount,
  };
};

import * as React from "react";
import type { SVGProps } from "react";
const KarpatkeyLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="flex-shrink-0"
    viewBox="0 0 355 355"
    width={116}
    height={116}
    {...props}
  >
    <defs>
      <clipPath id="clippath">
        <circle
          cx={177.5}
          cy={177.5}
          r={177.5}
          style={{
            fill: "none",
          }}
        />
      </clipPath>
      <style>{".karpatkey-logo-cls-2{fill:#232323}"}</style>
    </defs>
    <g
      id="Layer_1"
      style={{
        clipPath: "url(#clippath)",
      }}
    >
      <circle
        cx={177.5}
        cy={177.5}
        r={177.5}
        style={{
          fill: "#d9d9d9",
        }}
      />
      <path
        d="M333.6 267.1c-38-6-77.2-16.2-115.2-21.4-5-.5-12.5-1.3-17.6-1.8-6.5-.3-14.6-.8-21.1-.7-14 0-28.2 1.4-42 3.8-35.8 6.3-70.4 16.7-105.1 30.7l-1.9-4.6c25.4-10.2 51.5-18.6 78.2-24.9 18.2-4.4 42.7-8.9 63.6-9.4 4.3 0 10.2-.1 14.3 0 3.6.2 10.7.5 14.3.7 3.5.4 10.7 1.1 14.2 1.5 34.7 4.1 70.4 13.6 104.8 19.7l13.9 2.4-.6 4Z"
        className="karpatkey-logo-cls-2"
      />
      <path
        d="M345.4 242.6c-53.2-.7-105.7-12.8-158.9-8.7-19.8 1.5-39.2 5.6-58.1 11.5-31.6 9.8-62.2 22.8-91.4 38.3l-2.3-4.4c29.6-15.5 60.4-28.6 92.4-38.3 19.2-6 39-10.1 59.1-11.5 1.9-.1 10.9-.6 12.5-.7 5.7 0 11.9-.1 17.6 0 9.3.4 20.7.8 30 1.7 33.1 2.5 66.1 7.8 99.2 8.2v4Z"
        className="karpatkey-logo-cls-2"
      />
      <path
        d="M353.5 215.9c-54.3 6.6-109.5-.9-163.5 8.2-20.1 3.3-39.7 9.2-58.4 17.1-31.4 13.1-61.3 29.8-89.5 48.7l-2.8-4.2c22.8-15.2 46.8-28.9 71.6-40.5 26.4-12.6 54.3-22.4 83.4-26.4 18.6-2.8 37.7-3.6 56.5-4 33.9-.4 68.7 1 102.2-2.9l.5 4Z"
        className="karpatkey-logo-cls-2"
      />
      <path
        d="M357.3 188c-54.4 13.7-111.5 11.5-165.7 26.1-27 6.9-52.2 19.1-75.9 33.6-23.8 14.4-46.5 30.8-67.8 48.7l-3.2-3.8c32.5-26.8 67.6-51 105.8-68.8 32.2-14.6 67.3-21 102.2-24.9 34.6-4.1 69.7-6.2 103.6-14.7l1 3.9Z"
        className="karpatkey-logo-cls-2"
      />
      <path
        d="M357.9 163c-38.5 14.5-81.8 20.5-121.6 30-27.9 6.7-55.6 15.6-80.6 29.8-15.4 8.8-30.2 19.3-44.2 30.1l-.9.7h-1.1l1.9-.7c-19.9 15.5-38.8 32.5-56.5 50.6l-3.6-3.5c17.8-18.8 36.9-36.4 57.1-52.6h1.1l-1.9.7c17.5-13.9 35.8-27.1 55.7-37.4 27.4-14.2 57.4-22.7 87.3-29.6 22-5.1 51.1-11 72.6-17.1 10.6-3 21-6.4 31.1-10.3l3.6 9.3Z"
        className="karpatkey-logo-cls-2"
      />
      <path
        d="M353.9 137c-64.9 32.5-140.4 38.9-199.4 82.8-11.5 8.4-22.5 17.9-33 27.6-21.1 19.6-40.6 40.9-58.3 63.7l-4-3c26.3-34.8 56.2-67.4 90.7-94.3 23.3-18 50.2-30.8 77.5-41.2 13.6-5.1 27.4-9.8 41.2-14.4 27.4-9.2 54.7-18.4 79.8-31.7l5.5 10.7Z"
        className="karpatkey-logo-cls-2"
      />
      <path
        d="M346.4 112.7c-58.7 37.2-130 51.7-183.5 97.2-4 3.3-8.3 7.3-12 10.9-30 29.2-55.7 62.6-77.7 98.1l-4.3-2.6c14.8-25.4 31.6-49.7 50.4-72.5 14.1-17.1 29.3-33.7 46.6-47.7s36.7-25.6 56.4-35.8c38.7-19.9 80.8-35.3 116.6-59.4l7.7 11.7Z"
        className="karpatkey-logo-cls-2"
      />
      <path
        d="M334.8 89.4c-52.7 41.1-117.6 62.6-165.3 110-2.2 2.3-5.5 5.6-7.6 7.9-31.7 35.6-56.6 76.8-76.9 119.8l-4.6-2.1c12-27.2 26.1-53.3 42.3-78.3 13.3-20.2 27.7-40 45-57 25.3-25 56.1-43.9 86.2-61.6 25.4-15 50.8-29.8 73.3-48.1l7.5 9.4Z"
        className="karpatkey-logo-cls-2"
      />
      <path
        d="M320.8 69c-39.4 37.1-87.9 62.8-127 100.1-9.7 9.1-18.6 19.1-26.7 29.6-2.8 3.7-5.4 7.6-8.2 11.3l.4-.5c-19.4 29-35.2 60.5-48.2 92.8-4.3 10.8-8.3 21.8-11.9 32.9l-4.8-1.5c3.5-11.2 7.3-22.4 11.5-33.4 8.4-22 18.2-43.5 29.4-64.2 10.6-19.4 20.5-35.6 34.4-52.5 40.4-49 98.8-78.2 144-121.8l6.9 7.2Z"
        className="karpatkey-logo-cls-2"
      />
      <path
        d="M305.3 51.7c-40.8 46.1-93.8 79.8-128.2 131.3-7.2 10.7-13.3 21.9-18.9 33.6-19 40.1-32.7 82.7-41.1 126.2l-6.9-1.3c8.5-44.2 22.2-87.4 41.3-128.1 5.3-11 11.1-21.9 17.8-32.2 18-27.7 41.6-51.1 65.9-73.1 22-20.1 44.6-39.5 64.1-61.6l6 5.3Z"
        className="karpatkey-logo-cls-2"
      />
      <path
        d="M288.2 36.8c-35.4 47.9-82.3 86.9-109.6 140.4-13.4 26.3-21.8 55.1-28.8 83.8-6.7 28.9-11.3 58.3-13.2 87.8l-7-.5c2.1-30 7-59.7 14.1-88.8 7.3-29.1 16.1-58.1 30-84.9 14-26.7 33-50.3 52.9-72.7 19.8-22.3 40.6-43.8 58.4-67.5l3.2 2.4Z"
        className="karpatkey-logo-cls-2"
      />
      <path
        d="M272.6 26.1c-31.2 50.6-73.2 94.7-93 151.4-4.9 13.9-8.4 28.3-11.3 42.8-8.6 43.5-12 88.3-9.5 132.6l-7 .4c-1.5-30-.1-60.1 3.5-89.8 5.2-40.8 13.4-82.2 32.9-118.8C210.6 101.5 243.7 65 269.3 24l3.4 2.1Z"
        className="karpatkey-logo-cls-2"
      />
      <path
        d="M257.5 17.8c-52.9 99.8-82.6 127.3-82.2 248.3.1 29.5 2.5 59.1 7.7 88.1l-6.9 1.2c-9.6-59-10.1-119.7 1.2-178.5 6.3-29.4 18.5-57.1 32.9-83.3 14.2-26.2 30.2-51.2 43.7-77.6l3.6 1.8Z"
        className="karpatkey-logo-cls-2"
      />
      <path
        d="M243.5 11.7c-31.8 77.9-60.6 121.8-55.7 210.3 1.8 43.9 8.6 87.8 21.3 129.9l-6.7 2c-8.4-28.5-14-57.8-17.3-87.3-4.5-44.1-6.5-89.8 5.3-132.9 2.7-10.4 6.3-21.8 10.1-31.9 11.6-31.2 26.9-60.9 39.2-91.6l3.7 1.5Z"
        className="karpatkey-logo-cls-2"
      />
      <path
        d="M230.8 7.2C217.7 52.6 200 97 196.6 144.4c-1.9 25.2.8 50.5 4.6 75.5 7 42.8 18.7 85.1 36 124.8l-9.2 4c-22.4-54.5-35.6-112.6-38.7-171.5-.3-7.4-.4-14.8 0-22.2.6-14.5 2.8-29.6 5.9-43.9.5-2.7 1.7-8.1 2.4-10.8 7.8-32.1 19.7-62.9 29.3-94.2l3.8 1.1Z"
        className="karpatkey-logo-cls-2"
      />
      <path
        d="M219.6 4.2c-8.1 44-19.5 87.5-17.9 132.5.3 8.8 1.1 17.7 2.4 26.4 9.6 59.5 30.6 117.6 60.5 169.9l-10.4 5.9c-30.1-55.9-50.5-117.1-58.4-180.2-1.5-14.4-1.5-29.2-.5-43.7C198 77.2 208 40.2 215.8 3.4l3.9.8Z"
        className="karpatkey-logo-cls-2"
      />
      <path
        d="M210.1 2.2c-3.7 45.2-9.8 90.7-1.5 135.6 1.8 10.2 4.5 20.2 7.5 30.2 16.6 52.8 41.7 103.6 74.2 148.3l-11.4 8.1c-16.2-23.5-30-48.5-42-74.2-5.7-12.6-11.6-26.3-16.4-39.3-9.2-25-17.2-50.6-20.8-77.1-5.7-44 1.3-88.5 6.2-132l4 .4Z"
        className="karpatkey-logo-cls-2"
      />
      <path
        d="M202.3 1c.8 41.3.6 83.3 11.6 123.4 3.8 13.7 9 27.1 14.8 40.1 7.5 16.6 16.5 33.6 25.7 49.3 17.1 28.6 36.8 56.7 59.2 81.4l-12.1 10.5c-17.6-20.8-33.6-44.4-47.6-67.9-10.2-17.6-20.2-36.4-28.4-55.1-7-15.7-13.4-32-17.9-48.6-6.3-23.3-8.9-47.6-9.5-71.6-.8-20.5 0-41 .3-61.5h4Z"
        className="karpatkey-logo-cls-2"
      />
      <path
        d="M196.2.1c5.7 44.1 11.3 89.3 30.8 129.7 12.2 25.1 27.9 48.6 44.6 70.9 19.8 26 42 50.8 66.3 72.7l-13.7 14.5c-8.5-8.8-20.1-20.7-27.8-30-26.1-31.1-49.6-64.6-68.3-100.7-6.3-12-12-24.6-16.4-37.4C198.4 81.4 195.5 40.6 192.2.5l4-.4Z"
        className="karpatkey-logo-cls-2"
      />
      <path
        d="M177.5 7C271.5 7 348 83.5 348 177.5S271.5 348 177.5 348 7 271.5 7 177.5 83.5 7 177.5 7m0-7C79.6 0 0 79.6 0 177.5S79.6 355 177.5 355 355 275.4 355 177.5 275.4 0 177.5 0Z"
        className="karpatkey-logo-cls-2"
      />
    </g>
  </svg>
);
export default KarpatkeyLogo;

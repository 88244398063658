import { useFundPortfolioContext } from "@/context/fundPortfolioContext";
import type { Protocol, TokenList } from "@/data/interfaces/fund_state";
import { formatNumber, formatToUSD } from "@/utils/formatters";
import { capitalize } from "lodash";
import Image from "next/image";
import _ from "lodash";
import ErrorDisplay from "@/components/ErrorDisplay";
import EmptyState from "@/components/EmptyState";
import { CHAINS_DEBANK, PROTOCOLS_DEBANK } from "@/constants/wallet";

export default function PortfolioMoneyMarkets() {
  return (
    <div className="pt-10 border-t border-gray-400 px-5 space-y-10">
      <span className="font-mono text-2xl font-bold">Money Markets</span>
      <MoneyMarkets />
    </div>
  );
}

function MoneyMarkets() {
  const { data, isLoading, isError } = useFundPortfolioContext();

  const protocols = data?.protocols;

  if (isError) {
    return (
      <div className="p-10 flex items-center justify-center">
        <ErrorDisplay />
      </div>
    );
  }

  if (protocols?.length === 0) {
    return (
      <div className="py-40">
        <EmptyState />
      </div>
    );
  }

  return (
    <div className="grid grid-cols-[repeat(auto-fill,_minmax(20rem,auto))] gap-10 justify-center">
      {isLoading ? (
        <SkeletonLoader />
      ) : (
        <MoneyMarketsList protocols={protocols} />
      )}
    </div>
  );
}

function MoneyMarketsList({
  protocols,
}: {
  protocols: Protocol[] | undefined;
}) {
  return protocols?.map((protocol) => {
    return protocol.portfolio_item_list.map((item, index) => {
      const { supply_token_list, borrow_token_list, reward_token_list } =
        item.detail;

      const chain = CHAINS_DEBANK[protocol.chain] ?? capitalize(protocol.chain);

      const protocolName = protocol.id
        ? (PROTOCOLS_DEBANK[protocol.id] ?? protocol.name)
        : undefined;

      return (
        <div
          key={`${protocol.id}-${item.name}-${index}`}
          className="w-80 min-h-96 border border-gray-400 rounded p-4 gap-5 flex flex-col font-mono font-semibold text-base pb-10 "
        >
          <div className="gap-2 flex text-sm justify-between items-center">
            <span>{chain}</span>
            <div className="gap-2 flex items-center">
              <span>{protocolName}</span>
              {protocol.logo_url && (
                <Image
                  src={protocol.logo_url}
                  width={24}
                  height={24}
                  alt="Protocol Logo"
                />
              )}
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <span className="text-xl">{item.name}</span>
            <span>{formatToUSD(item.stats.net_usd_value)}</span>
          </div>
          <div className="gap-10 flex-col flex">
            {supply_token_list && supply_token_list.length > 0 && (
              <TokensList title="Supply" tokenList={supply_token_list} />
            )}
            {borrow_token_list && borrow_token_list.length > 0 && (
              <TokensList title="Borrow" tokenList={borrow_token_list} />
            )}
            {reward_token_list && reward_token_list.length > 0 && (
              <TokensList
                title="Unclaimmed rewards"
                tokenList={reward_token_list}
              />
            )}
          </div>
        </div>
      );
    });
  });
}

function TokensList({
  tokenList,
  title,
}: {
  tokenList: TokenList;
  title: string;
}) {
  return (
    <div>
      <div className="border-b-2 border-gray-400">{title}</div>
      {tokenList.map((token, index) => {
        return (
          <div key={`${token.name}-${index}`}>
            <div className="border-b border-gray-400 gap-3 flex justify-between items-start py-3">
              <span>{token.symbol}</span>
              <div className="flex-col flex text-end">
                <span>{formatNumber(token.amount)}</span>
                <span className="font-normal text-xs">
                  {formatToUSD(token.amount * token.price)}
                </span>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

function SkeletonLoader() {
  return (
    <>
      {_.times(6, (i) => (
        <div
          key={i}
          className="w-96 h-96 bg-gray-200 animate-pulse rounded-lg p-4"
        />
      ))}
    </>
  );
}

import type { SVGProps } from "react";
const Arbitrum = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    viewBox="0 0 373.93 422.01"
    width={20}
    height={20}
    {...props}
  >
    <defs>
      <style>{".cls-2{stroke-width:0;fill:#fff}"}</style>
    </defs>
    <path
      d="M14.16 128.27v165.47c0 10.53 5.61 20.28 14.76 25.55l143.29 82.69a29.536 29.536 0 0 0 29.52 0l143.29-82.69a29.477 29.477 0 0 0 14.76-25.55V128.27c0-10.53-5.61-20.28-14.76-25.55L201.73 20.03a29.536 29.536 0 0 0-29.52 0L28.92 102.72c-9.06 5.27-14.76 15.02-14.76 25.55Z"
      style={{
        strokeWidth: 0,
        fill: "none",
      }}
    />
    <path
      d="m218.3 243.07-20.46 56.02c-.6 1.55-.6 3.28 0 4.83l35.13 96.42 40.66-23.48-48.77-133.79c-1.12-3.11-5.44-3.11-6.56 0ZM259.21 148.81c-1.12-3.11-5.44-3.11-6.56 0l-20.46 56.02c-.6 1.55-.6 3.28 0 4.83l57.57 157.88 40.66-23.48-71.21-195.25Z"
      className="cls-2"
    />
    <path
      d="M186.97 26.15c1.04 0 1.99.26 2.93.78l155.03 89.51c1.81 1.04 2.93 2.93 2.93 5.01v179.02c0 2.07-1.12 3.97-2.93 5.01l-155.03 89.6c-.86.52-1.9.78-2.93.78s-1.99-.26-2.93-.78L29.09 305.57c-1.81-1.04-2.93-2.93-2.93-5.01V121.45c0-2.07 1.12-3.97 2.93-5.01l155.03-89.51c.86-.52 1.9-.78 2.85-.78m0-26.15c-5.52 0-11.05 1.47-15.97 4.32L15.97 93.83C6.13 99.52 0 110.06 0 121.45v179.02c0 11.39 6.13 22.01 15.97 27.71L171 417.69c4.92 2.85 10.44 4.32 15.97 4.32s11.05-1.47 15.97-4.32l155.03-89.51c9.93-5.7 15.97-16.23 15.97-27.71V121.45c0-11.39-6.13-22.01-15.97-27.71L203.02 4.32C198.01 1.47 192.49 0 186.96 0Z"
      className="cls-2"
    />
    <path
      d="M173.93 108.67h-39.27c-2.93 0-5.61 1.81-6.56 4.57L43.85 344.23l40.66 23.48 92.79-254.38c.78-2.24-.86-4.66-3.37-4.66ZM242.73 108.67h-39.27c-2.93 0-5.61 1.81-6.56 4.57l-96.24 263.79 40.66 23.48 104.69-287.09c.86-2.33-.86-4.75-3.28-4.75Z"
      className="cls-2"
    />
  </svg>
);
export default Arbitrum;

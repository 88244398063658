import * as React from "react";
import type { SVGProps } from "react";

interface ArrowUpRightProps extends SVGProps<SVGSVGElement> {
  size?: number;
  color?: string;
}
const ArrowUpRight = ({
  size = 16,
  color = "#222",
  ...props
}: ArrowUpRightProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="transparent"
    {...props}
  >
    <path
      fill={color}
      d="M5.5 3v1h6.295L3.5 12.295l.705.705L12.5 4.705V11h1V3h-8Z"
    />
  </svg>
);
export default ArrowUpRight;

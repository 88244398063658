import type { SVGProps } from "react";
const Optimism = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M10 20c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10Zm-2.916-7.344c-.596 0-1.084-.14-1.464-.42-.376-.284-.564-.692-.564-1.216 0-.112.012-.244.036-.404a24.3 24.3 0 0 1 .276-1.3c.34-1.376 1.22-2.064 2.636-2.064.384 0 .732.064 1.036.196a1.532 1.532 0 0 1 .984 1.472c0 .104-.012.236-.036.396-.076.444-.164.88-.272 1.3-.176.684-.476 1.2-.908 1.54-.428.336-1.004.5-1.724.5Zm.108-1.08c.28 0 .516-.084.712-.248.2-.164.344-.416.428-.76.116-.472.204-.88.264-1.232.02-.104.032-.212.032-.324 0-.456-.236-.684-.712-.684a1.1 1.1 0 0 0-.72.248c-.196.164-.336.416-.42.76-.092.336-.18.744-.272 1.232-.02.1-.032.204-.032.316-.004.464.24.692.72.692Zm3.052.956c.032.036.072.052.128.052h1.02a.22.22 0 0 0 .144-.052.215.215 0 0 0 .084-.136l.344-1.644h1.012c.656 0 1.172-.136 1.548-.412.384-.276.636-.7.76-1.276.028-.136.044-.268.044-.392 0-.436-.172-.768-.512-1-.336-.228-.78-.344-1.336-.344h-1.996a.22.22 0 0 0-.144.052.2.2 0 0 0-.084.136l-1.036 4.88a.195.195 0 0 0 .024.136Zm3.332-2.988a.886.886 0 0 1-.548.172h-.86l.284-1.344h.9c.204 0 .352.04.436.12.084.076.128.188.128.332 0 .064-.008.14-.024.224a.822.822 0 0 1-.316.496Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default Optimism;

import type { SVGProps } from "react";
const Base = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#fff"
        d="M9.982 20C15.515 20 20 15.523 20 10S15.515 0 9.982 0C4.734 0 .428 4.03 0 9.16h13.24v1.68H0C.428 15.97 4.734 20 9.982 20Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default Base;

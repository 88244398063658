import type { SVGProps } from "react";

const Ethereum = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill="#ECECEC"
      d="m9.824 0-.133.456V13.68l.133.134 6.074-3.629L9.824 0Z"
    />
    <path
      fill="#E3E3E3"
      stroke="#E7E5E3"
      strokeWidth={1.5}
      d="m4.778 9.926 4.296-7.204v9.77L4.778 9.926Z"
    />
    <path
      fill="#F5F5F5"
      d="m9.824 14.976-.074.092v4.711l.074.221 6.078-8.65-6.078 3.626Z"
    />
    <path fill="#E3E3E3" d="M9.824 20v-5.024L3.75 11.35 9.824 20Z" />
    <path fill="#fff" d="m9.824 13.814 6.074-3.628-6.074-2.79v6.418Z" />
    <path fill="#F6F6F6" d="m3.75 10.186 6.074 3.628V7.396l-6.074 2.79Z" />
  </svg>
);
export default Ethereum;

"use client";

import ArrowUpRight from "@/components/Icons/ArrowUpRight";
import { ChainIcon } from "@/components/Icons/Chains/Chain";
import { useConfigContext } from "@/context/configContext";
import type { SafeConfig } from "@/data/interfaces/config";
import { openAddressBlockExplorer, shortenAddress } from "@/utils/addresses";

import { Button } from "@headlessui/react";

export default function SafeAddresses() {
  const { fund } = useConfigContext();
  return (
    <div className="py-10 flex flex-row flex-wrap">
      {fund.safes.map((safe) => (
        <SafeAddress key={safe.chain} safe={safe} />
      ))}
    </div>
  );
}

function SafeAddress({ safe }: { safe: SafeConfig }) {
  return (
    <Button
      onClick={() => openAddressBlockExplorer(safe.address, safe.chain)}
      className="bg-gray-900 rounded-[80px] py-3 gap-3 flex flex-row items-center mx-4 px-4 my-2"
    >
      <ChainIcon chainId={safe.chain} />
      <span className="font-mono font-bold text-base text-gray-100">
        {shortenAddress(safe.address)}
      </span>
      <ArrowUpRight size={20} color="#fff" />
    </Button>
  );
}

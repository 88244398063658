import { useFundPortfolioContext } from "@/context/fundPortfolioContext";
import WalletTable from "./tables/WalletTable";
import { useMemo } from "react";
import { columns } from "./tables/WalletTableData";

export default function PortfolioWallet() {
  const { data, isLoading, isError } = useFundPortfolioContext();

  const walletColumns = useMemo(
    () => columns(data?.totalWalletUsdValue),
    [data?.totalWalletUsdValue],
  );
  return (
    <div className="py-10 border-t border-gray-400 px-5 space-y-10">
      <span className="font-mono text-2xl font-bold">Wallet</span>

      <WalletTable
        data={data?.tokens}
        isLoading={isLoading}
        isError={isError}
        columns={walletColumns}
      />
    </div>
  );
}

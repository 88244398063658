import React, { useMemo } from "react";
import { Line } from "react-chartjs-2";
import type { ChartData, ChartOptions } from "chart.js";
import { formatToCompactUSD, formatToUSD } from "@/utils/formatters";
import "chartjs-adapter-date-fns";
import { DateRangeType } from "@/constants/date";

interface LineChartProps {
  data: ReportChartData[];
  dateRange: DateRangeType;
  label: string;
}

interface ReportChartData {
  timestamp: number;
  value: number;
}

export default function LineChart({ data, dateRange, label }: LineChartProps) {
  const options = useMemo<ChartOptions<"line">>(
    () => ({
      plugins: {
        datalabels: {
          display: false,
        },
        legend: {
          display: false,
        },
        tooltip: {
          displayColors: false,
          callbacks: {
            label: (tooltipItem) => {
              if (!data) return;
              const value = tooltipItem.parsed.y;
              return `${label}: ${formatToUSD(value)}`;
            },
          },
        },
      },
      maintainAspectRatio: false,
      interaction: {
        intersect: false,
      },
      scales: {
        y: {
          min: Math.min(...data.map((d) => d.value)) > 0 ? undefined : 0,
          grace: "20%",
          afterFit: (scale) => {
            scale.width = 100;
          },
          grid: {
            color: "#B6B6B6",
            lineWidth: 1,
          },
          border: {
            dash: [6, 4],
            color: "#808080",
            width: 2,
          },
          ticks: {
            padding: 10,
            color: "#808080",
            callback: (value) => {
              const amount = Number.parseFloat(value.toString());
              return formatToCompactUSD(amount);
            },
          },
        },
        x: {
          type: "time",
          time: {
            tooltipFormat: "MMM dd, yyyy, HH:mm",
            minUnit: dateRange !== DateRangeType.Day ? "day" : "hour",
            isoWeekday: true,
          },
          grid: {
            color: "#B6B6B6",
            lineWidth: 1,
          },
          border: {
            dash: [6, 4],
            color: "#808080",
            width: 2,
          },
          ticks: {
            color: "#808080",
            maxTicksLimit: 10,
          },
        },
      },
    }),
    [data, label, dateRange],
  );

  const formattedData = useMemo<ChartData<"line">>(
    () => ({
      datasets: [
        {
          data: data.map((d) => ({
            x: d.timestamp,
            y: d.value,
          })),
          borderColor: "#54B9A1",
          pointBackgroundColor: "#54B9A1",
          borderWidth: 2,
          pointRadius: 0,
        },
      ],
    }),
    [data],
  );

  return <Line data={formattedData} options={options} />;
}

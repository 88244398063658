import Arbitrum from "./Arbitrum";
import Base from "./Base";
import Ethereum from "./Ethereum";
import Gnosis from "./Gnosis";
import Optimism from "./Optimism";

interface ChainIconProps {
  chainId: string;
  size?: number;
}

type ChainId = "base" | "optimism" | "ethereum" | "gnosis" | "arbitrum";
type ChainIconComponent = React.ComponentType<{
  width: number;
  height: number;
}>;

const CHAIN_ICONS: Record<ChainId, ChainIconComponent> = {
  base: Base,
  optimism: Optimism,
  ethereum: Ethereum,
  gnosis: Gnosis,
  arbitrum: Arbitrum,
} as const;

export function ChainIcon({ chainId, size = 20 }: ChainIconProps) {
  const Icon = CHAIN_ICONS[chainId as ChainId] || CHAIN_ICONS.ethereum;
  return <Icon width={size} height={size} />;
}

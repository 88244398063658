import { useQuery } from "@tanstack/react-query";
import type { DateRangeType } from "@/constants/date";
import { useConfigContext } from "@/context/configContext";
import { calculateScaledSharePrice } from "@/utils/math";
import { getFundStateTimeseries } from "@/data/api/fund";
import { formatToUSD } from "@/utils/formatters";

export interface HistoricalSharePriceParams {
  startDate: string;
  endDate: string;
  dateRangeFilter: DateRangeType;
}

export const useHistoricalSharePrice = ({
  startDate,
  endDate,
  dateRangeFilter,
}: HistoricalSharePriceParams) => {
  const { fund } = useConfigContext();
  const { data, error, isFetching, isFetchedAfterMount, isError } = useQuery({
    queryKey: ["historicalSharePrice", dateRangeFilter],
    queryFn: () => getFundStateTimeseries(fund.name, startDate, endDate),
    staleTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  const chartData = data?.map((fund) => {
    const scaledSharePrice = calculateScaledSharePrice(fund.supply, fund.usd);

    return {
      timestamp: Number(fund.maxTimestamp) * 1000,
      value: Number(formatToUSD(scaledSharePrice, false)),
    };
  });

  return {
    data: chartData,
    isError,
    error,
    isFetching,
    isFetchedAfterMount,
  };
};

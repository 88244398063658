import { useFundPortfolioContext } from "@/context/fundPortfolioContext";
import { FundsByAssetChart } from "./charts/FundsByAssetChart";
import { FundsByChainChart } from "./charts/FundsByChainChart";
import { FundsByProtocolChart } from "./charts/FundsByProtocolChart";
import EmptyState from "@/components/EmptyState";
import { useFundStateContext } from "@/context/fundStateContext";

export default function PortfolioAssetsDistribution() {
  const { data: portfolioData } = useFundPortfolioContext();
  const { data: stateData } = useFundStateContext();
  if (
    stateData?.nav === 0n &&
    portfolioData?.tokens?.length === 0 &&
    portfolioData?.protocols?.length === 0
  )
    return (
      <div className="py-40">
        <EmptyState />
      </div>
    );

  return (
    <div className="px-5 grid sm:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-10">
      <FundsByChainChart />
      <FundsByProtocolChart />
      <FundsByAssetChart />
    </div>
  );
}

export function AssetDistributionWrapper({
  children,
  title,
}: {
  children: React.ReactNode;
  title: string;
}) {
  return (
    <div className="border rounded p-5 min-h-[460px] max-h-[600px] border-gray-400">
      <p className="text-gray-900 text-lg font-bold font-sans pb-10">{title}</p>
      <div className="min-h-[460px] max-h-[600px]">{children}</div>
    </div>
  );
}

export function AssetsDistributionLoader() {
  return <div className="h-24" />;
}

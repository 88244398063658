import { useFundStateContext } from "@/context/fundStateContext";
import { formatNavToUSDUnits } from "@/utils/formatters";
import { DoughnutChart } from "./PortfolioDoughnutChart";
import {
  AssetDistributionWrapper,
  AssetsDistributionLoader,
} from "../PortfolioAssetsDistribution";
import { useMemo } from "react";
import _ from "lodash";
import ErrorDisplay from "@/components/ErrorDisplay";

export function FundsByChainChart() {
  const { data, isLoading, isError } = useFundStateContext();

  const navsPerChain = data?.navPerChain;

  const chartData = useMemo(() => {
    const data = navsPerChain
      ? _.map(navsPerChain, (value, key) => ({
          label: _.capitalize(key),
          value: Number(formatNavToUSDUnits(value.usd, false)),
        })).filter((item) => item.value > 0)
      : undefined;

    return _.orderBy(data, ["value"], ["desc"]);
  }, [navsPerChain]);

  if (chartData?.length === 0) return null;

  return (
    <AssetDistributionWrapper title="Total funds by blockchain">
      {isLoading ? (
        <AssetsDistributionLoader />
      ) : isError || !chartData ? (
        <div className="flex-col flex py-20 justify-center">
          <ErrorDisplay />
        </div>
      ) : (
        <DoughnutChart data={chartData} />
      )}
    </AssetDistributionWrapper>
  );
}

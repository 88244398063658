import type { SVGProps } from "react";
const Gnosis = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1936.41 2000"
    width={20}
    height={20}
    {...props}
  >
    <g fill="#fff">
      <path d="m1777.6 500-73.6 73.6c58.5 92 76.9 207.4 36.8 317.7-66.9 187.3-274.2 286-463.2 219.1-26.8-10-51.8-21.7-73.6-36.8l-235.8 235.8L762.5 1102c-95.3 63.5-217.4 81.9-332.8 41.8-194-70.2-294.29-282.6-224.1-474.9 10-30.1 25.1-56.9 41.8-81.9l-88.6-88.6-16.7 28.4A965.18 965.18 0 0 0 0 1030.1C-1.7 1563.5 433.1 2000 966.5 2000h1.7c533.4 0 966.6-433.1 968.21-966.6a972.06 972.06 0 0 0-140.5-503.3z" />
      <path d="M356.2 695.7a200.22 200.22 0 0 0-40.1 120.4c0 108.7 88.6 197.3 197.3 197.3 45.2 0 87-15.1 120.4-41.8zM1324.4 954.8c31.8 21.7 68.6 33.4 110.4 33.4 108.7 0 197.3-88.6 197.3-197.3 0-40.1-11.7-78.6-33.4-110.4zM971.5 1162.2 172.2 359.5l31.8-33.4C401.3 117.1 670.5 0 959.8 0h1.7c292.6 0 573.6 125.4 769.2 342.8l30.1 33.4zM302.7 359.5l668.9 670.6 660.5-657.2C1454.8 195.7 1214 93.6 963.2 93.6h-1.7c-249.2 0-481.6 93.7-658.8 265.9" />
    </g>
  </svg>
);
export default Gnosis;

import { CHAINS_DEBANK, PROTOCOLS_DEBANK } from "@/constants/wallet";
import type { Token } from "@/data/interfaces/fund_state";
import {
  formatNumber,
  formatToPercentage,
  formatToUSD,
} from "@/utils/formatters";
import { createColumnHelper } from "@tanstack/react-table";
import _ from "lodash";

export const TABLE_PAGE_SIZE = 5;

const columnHelper = createColumnHelper<Token>();

export const columns = (totalWalletUsdValue: number | undefined) => [
  columnHelper.accessor("symbol", {
    header: "Token",
    cell: (info) => {
      const row = info.row.original;
      const chain = CHAINS_DEBANK[row.chain] ?? _.capitalize(row.chain);
      const protocolKey = row.protocol_id;
      const protocol = protocolKey
        ? (PROTOCOLS_DEBANK[protocolKey] ?? _.capitalize(protocolKey))
        : undefined;
      return (
        <div className="flex-col flex">
          <span>{info.getValue()}</span>
          <span className="text-sm font-normal">{protocol}</span>
          <span className="text-sm font-normal">{chain}</span>
        </div>
      );
    },
  }),
  columnHelper.accessor("price", {
    header: "Price",
    cell: (info) => formatToUSD(info.getValue()),
  }),
  columnHelper.accessor("amount", {
    header: () => <div className="flex justify-end">Amount</div>,
    cell: (info) => {
      const price = info.row.original.price;
      return (
        <div className="flex-col flex items-end">
          <span>{formatNumber(info.getValue())}</span>
          <span className="text-sm font-normal">
            {formatToUSD(price * info.getValue())}
          </span>
        </div>
      );
    },
  }),
  columnHelper.accessor((row) => row.price * row.amount, {
    id: "share",
    header: () => <div className="flex justify-end">Share</div>,
    cell: (info) => {
      const tokenUsdValue = info.getValue();

      return (
        <div className="flex justify-end">
          {totalWalletUsdValue
            ? formatToPercentage(tokenUsdValue / totalWalletUsdValue)
            : "-"}
        </div>
      );
    },
  }),
];

"use client";

import { createContext, useContext, type ReactNode } from "react";
import { useFundPortfolio } from "@/hooks/nav/useFundPortfolio";
import type { Protocol, Token } from "@/data/interfaces/fund_state";

interface FundPortfolioContext {
  data: FundPortfolio | undefined;
  isFetching: boolean;
  isLoading: boolean;
  isFetchedAfterMount: boolean;
  isError: boolean;
}

interface FundPortfolio {
  protocols: Protocol[] | undefined;
  tokens: Token[] | undefined;
  totalWalletUsdValue: number;
}

const FundPortfolioContext = createContext<FundPortfolioContext | undefined>(
  undefined,
);

export const FundPortfolioProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { data, isFetching, isFetchedAfterMount, isLoading, isError } =
    useFundPortfolio();

  return (
    <FundPortfolioContext.Provider
      value={{
        data,
        isFetching,
        isLoading,
        isFetchedAfterMount,
        isError,
      }}
    >
      {children}
    </FundPortfolioContext.Provider>
  );
};

export const useFundPortfolioContext = () => {
  const context = useContext(FundPortfolioContext);
  if (!context) {
    throw new Error(
      "useFundPortfolioContext must be used within a FundPortfolioProvider",
    );
  }
  return context;
};

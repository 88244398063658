import { useConfigContext } from "@/context/configContext";
import { getFundPortfolio } from "@/data/api/fund";
import type { TokenList } from "@/data/interfaces/fund_state";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import _ from "lodash";

export type WalletToken = {
  symbol: string;
  usdPrice: number;
  amount: number;
  totalUsdValue: number;
  share?: number;
};

export const useFundPortfolio = () => {
  const { fund } = useConfigContext();
  const { data, error, isFetching, isFetchedAfterMount, isLoading, isError } =
    useQuery({
      queryKey: ["fundPortfolio", fund.name],
      queryFn: () => getFundPortfolio(fund.name),
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    });

  const validTokens = data?.tokens.filter((token) => token.price);

  const totalWalletUsdValue = useMemo(
    () =>
      validTokens?.reduce((acc, token) => {
        return acc + token.amount * token.price;
      }, 0) ?? 0,
    [validTokens],
  );

  return {
    data: {
      protocols: data?.protocols,
      tokens: validTokens,
      totalWalletUsdValue: totalWalletUsdValue,
    },
    isError,
    isLoading,
    error,
    isFetching,
    isFetchedAfterMount,
  };
};

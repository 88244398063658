import Big from "big.js";
import { DECIMALS } from "@/constants/wallet";
import {
  formatToPercentage,
  formatToSharesUnits,
  formatToUSD,
  formatToUSDCUnits,
  percentageFormatter,
} from "@/utils/formatters";
import { multiplyBigIntWithNumber } from "@/utils/math";
import { differenceInDays } from "date-fns";

export function calculateTotalReturnPercentage(
  scaledSharePrice: number,
  initialSharePrice: number,
): string {
  const sharePriceDifference = scaledSharePrice - initialSharePrice;
  const totalReturnPercentage = sharePriceDifference / initialSharePrice;

  return formatToPercentage(totalReturnPercentage);
}

export function calculateAnnualizedReturn(
  scaledSharePrice: number,
  initialSharePrice: number,
  startTimestamp: bigint,
): string {
  const issueDate = new Date(Number(startTimestamp) * 1000);
  const currentDate = new Date();

  const daysHeld = differenceInDays(currentDate, issueDate) + 1;

  const cumulativeReturn =
    (scaledSharePrice - initialSharePrice) / initialSharePrice;

  const yearsOfHolding = 365 / Math.max(daysHeld, 30);

  const annualizedReturn = (1 + cumulativeReturn) ** yearsOfHolding - 1;

  return formatToPercentage(annualizedReturn);
}

export function calculateNavUSDValueBasedOnShares(
  scaledSharePrice: number,
  sharesBalance: bigint,
): string {
  const navValue = multiplyBigIntWithNumber(sharesBalance, scaledSharePrice);
  const formattedNavValue = formatToSharesUnits(navValue, false);
  return formatToUSD(Number(formattedNavValue));
}

export function calculateUserTotalReturn(
  scaledSharePrice: number,
  depositUsdc: string,
  redeemedUsdc: string,
  sharesBalance: bigint,
): string {
  const depositUsdcBig = Big(depositUsdc.toString());
  const redeemedUsdcBig = Big(redeemedUsdc.toString());

  const realizedReturn = redeemedUsdcBig.minus(depositUsdcBig);
  const totalShareValue = multiplyBigIntWithNumber(
    sharesBalance,
    scaledSharePrice,
  );

  const scaling = Big(10).pow(DECIMALS.SHARES - DECIMALS.USDC);
  const scaledRealizedReturn = realizedReturn.times(scaling);

  const totalReturn = Big(totalShareValue.toString())
    .plus(scaledRealizedReturn.toString())
    .toFixed(0, 0);

  const formattedTotalReturn = formatToSharesUnits(BigInt(totalReturn), false);

  return formatToUSD(Number(formattedTotalReturn));
}

export function calculateUserRealizedReturn(
  depositUsdc: string,
  redeemUsdc: string,
): string {
  const depositBig = Big(depositUsdc);
  const redeemBig = Big(redeemUsdc);

  const realizedReturn = redeemBig.minus(depositBig).toFixed(0, 0);

  const formattedRealizedReturn = formatToUSDCUnits(
    BigInt(realizedReturn),
    false,
  );

  return formatToUSD(Number(formattedRealizedReturn));
}

export const calculateCapitalUtilization = (
  usdc: bigint,
  nav: bigint,
): string => {
  if (nav === 0n) {
    return percentageFormatter.format(0);
  }
  const totalBalanceBig = Big(usdc.toString());
  const navBig = Big(nav.toString());

  const scalling = Big(10).pow(DECIMALS.NAV - DECIMALS.USDC);

  const scaledUSDC = totalBalanceBig.times(scalling);

  let unusedUSDCPercentage = scaledUSDC.div(navBig).toNumber();

  unusedUSDCPercentage = Math.max(0, Math.min(1, unusedUSDCPercentage));

  return percentageFormatter.format(1 - unusedUSDCPercentage);
};
